import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 1080,
    background: theme.palette.background.default,
  },
  main: {
    paddingBottom: 80,
  },
}))

const App = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <main className={classes.main}>{children}</main>
    </div>
  )
}

App.propTypes = {
  children: PropTypes.node.isRequired,
}

export default App
