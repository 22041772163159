import React from "react"
import PropTypes from "prop-types"
import CssBaseline from "@material-ui/core/CssBaseline"
import { createTheme, responsiveFontSizes } from "@material-ui/core"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { CookiesProvider } from "react-cookie"
import App from "./App"

const fontFamily = [
  "Poppins",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(",")

const button = {
  fontFamily: "inherit",
  fontWeight: 600,
  fontSize: "1rem",
  lineHeight: 1.3,
  letterSpacing: ".04em",
  textTransform: "unset",
  textDecoration: "none",
}

const theme = createTheme({
  palette: {
    border: {
      gradient: {
        main: "#D2D2D2",
        side: "#FFFFFF",
      },
    },
    primary: {
      main: "#2164e8", // "#6600cc",
    },
    background: {
      hover: "#e4e6e9",
      default: "#ffffff",
      paper: "#f0f0f0",
      paperTwo: "#fafafb",
    },
    shadow: {
      card: {
        normal: "0px 10px 20px rgb(0 0 0 / 5%)",
        hover: "0px 10px 20px rgb(0 0 0 / 10%)",
      },
      quote: {
        normal: "0px 20px 40px rgb(0 0 0 / 25%)",
        hover: "0px 30px 60px rgb(0 0 0 / 35%)",
      },
    },
    line: "#e2e2e2",
    type: "light",
  },
  typography: {
    fontFamily: fontFamily,
    button: button,
    h6: {
      fontSize: "1rem",
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: "1rem",
    },
  },
})

const Root = ({ children }) => {
  const themeResponsiveFonts = responsiveFontSizes(theme)
  return (
    <CookiesProvider>
      <MuiThemeProvider theme={themeResponsiveFonts}>
        <React.Fragment>
          <CssBaseline />
          <App children={children} />
        </React.Fragment>
      </MuiThemeProvider>
    </CookiesProvider>
  )
}

Root.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Root
